/** @format */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import client from './provider';
/**
 * Вызовы. Список
 *
 * Получение списка вызовов по параметрам запроса
 *
 * https://uponblog.ru/api_list.php?page=4
 * @param {IChallengeListProps} data - Параметры запроса
 * @returns ICard[]
 */
export function getChallengeList(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_list.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Подробная информация.
 *
 * Получение информации о вызове по tokenChallenge
 *
 * https://uponblog.ru/api_list.php?page=6
 * @param {IChallengeInfoProps} data - Параметры запроса
 * @returns ICardInfo
 */
export function getChallengeInfo(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.token_challenge) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = 'challenge_info.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 2:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Обновление.
 *
 * Шаг 2. Обновление вызова по tokenChallenge
 *
 * https://uponblog.ru/api_list.php?page=12
 * @param {IChallengeUpdateInfo} data - Параметры запроса
 * @returns ICardInfo
 */
export function updateChallengeInfo(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.token_challenge) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = 'challenge_update.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 2:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 3:
                    error_2 = _a.sent();
                    console.log(error_2);
                    throw error_2;
                case 4: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Обновление.
 *
 * Шаг 3. Обновление медиа вызова
 *
 * https://uponblog.ru/api_list.php?page=12
 * @param {IChallangeFileResultDTO} data - Параметры запроса
 * @returns ICardInfo
 */
export function updateChallengeMedia(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, dataToSend, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_update_file.php';
                    dataToSend = {};
                    if (data.images && data.images.length) {
                        data.images.forEach(function (file) {
                            dataToSend['images[]'] = dataToSend['images[]'] ? __spreadArray(__spreadArray([], dataToSend['images[]'], true), [file], false) : [file];
                        });
                    }
                    if (data.video && data.video.length) {
                        data.video.forEach(function (file) {
                            dataToSend['video[]'] = dataToSend['video[]'] ? __spreadArray(__spreadArray([], dataToSend['video[]'], true), [file], false) : [file];
                        });
                    }
                    if (data.token_challenge) {
                        dataToSend.token_challenge = data.token_challenge;
                    }
                    return [4 /*yield*/, client.customPost(url, dataToSend, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, response.data];
            }
        });
    });
}
/**
 * Вызов. Удаление файла (изображение или видео).
 *
 * /api/challenge_file_delete.php
 *
 * https://uponblog.ru/api_list.php?page=104
 *
 */
export function deleteChallengeMedia(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_file_delete.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Получение категорий, режимов, подкатегорий
 *
 * Получаем списки всех режимов, категорий и подкатегорий вызовов
 *
 * https://uponblog.ru/api_list.php?page=5
 * @param {IChallangeMetaDataInfoProps} data - [wip] пустой объект
 * @returns IChallangeMeta
 */
export function getChallengeMetaData(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'data.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Удаление.
 *
 * Удаляем вызов
 *
 * https://uponblog.ru/api_list.php?page=7
 * @param {IChallengeInfoProps} data
 * @returns IChallengeInfoProps
 */
export function deleteChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_delete.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов, внешняя ссылка
 *
 * Получение внешней ссылки на вызов
 *
 * https://uponblog.ru/api_list.php?page=8
 * @param {IChallengeInfoProps} data
 * @returns IChallangeLink
 */
export function getChallangeLink(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_link_out.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов, получение токена вызова по внешней ссылке
 *
 * https://uponblog.ru/api_list.php?page=11
 *
 * @param {ILinkProps} data
 * @returns Pick<IChallangeLink, 'token_challenge'>
 */
export function getChallengeToken(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_token_by_link_out.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов, генерация QR кода
 *
 * https://uponblog.ru/api_list.php?page=9
 *
 * @param {IChallengeInfoProps} data
 * @returns IChallangeQRResponse
 */
export function getChallengeQR(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.token_challenge) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    url = 'challenge_link_out_qr.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 2:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 3:
                    error_3 = _a.sent();
                    console.log(error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Запуск.
 *
 * Старт вызова создателем.
 *
 *
 * https://uponblog.ru/api_list.php?page=10
 *
 * @param {IChallengeInfoProps} data
 * @returns IChallangeResponse
 */
export function startChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_start.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Жалоба.
 *
 * Пожаловаться на вызов
 *
 * https://uponblog.ru/api_list.php?page=13
 *
 * @param {IComplainData} data
 * @returns IChallangeResponse
 */
export function complainOnChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_claim.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 *
 * Вызов. Просмотр участников вызова.
 *
 * https://uponblog.ru/api_list.php?page=15
 *
 * @param {IChallengeInfoProps} data
 * @returns
 */
export function getChallengePlayers(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_list.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Оценка результата
 *
 * https://uponblog.ru/api_list.php?page=17
 *
 * @param {IChallangeResultData} data
 * @returns
 */
export function setChallengeResult(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_result_estimate.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 *  Вызовы. Результаты (ожидающие проверки)
 *
 *  TODO: Дополнить описание
 *
 * https://uponblog.ru/api_list.php?page=18
 *
 * @param {IChallangeResponse} data
 *
 * @returns
 */
export function getChallengeExpectedResults(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_result_expect.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызовы. Просмотр результата
 *
 * https://uponblog.ru/api_list.php?page=19
 *
 * @param data
 * @returns IChallengeResultResponseDTO
 */
export function getChallengeResultDetail(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!((data === null || data === void 0 ? void 0 : data.token_challenge) && !!(data === null || data === void 0 ? void 0 : data.id_result))) return [3 /*break*/, 2];
                    url = 'challenge_users_result_info.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Создание.
 *
 * Шаг 2. Создание вызова
 *
 * https://uponblog.ru/api_list.php?page=5
 * @param {IChallengeCreateInfo} data - Параметры запроса
 * @returns ICardInfo
 */
export function createChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_create.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * TODO: ПЕРЕДЕЛАТЬ ИНТЕРФЕЙСЫ
 *
 * Вызов. Создание.
 *
 * Шаг 3. Загрузка медиа
 *
 * https://uponblog.ru/api_list.php?page=5
 *
 * @param {IChallengeCreateInfo} data - Параметры запроса
 * @returns ICardInfo
 */
export function uploadChallengeMedia(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, dataToSend, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_create_file.php';
                    dataToSend = {};
                    if (data.images && data.images.length) {
                        data.images.forEach(function (file) {
                            dataToSend['images[]'] = dataToSend['images[]'] ? __spreadArray(__spreadArray([], dataToSend['images[]'], true), [file], false) : [file];
                        });
                    }
                    if (data.video && data.video.length) {
                        data.video.forEach(function (file) {
                            dataToSend['video[]'] = dataToSend['video[]'] ? __spreadArray(__spreadArray([], dataToSend['video[]'], true), [file], false) : [file];
                        });
                    }
                    if (data.token_challenge) {
                        dataToSend.token_challenge = data.token_challenge;
                    }
                    return [4 /*yield*/, client.customPost(url, dataToSend, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, response.data];
                    }
                    return [2 /*return*/, response.data];
            }
        });
    });
}
/**
 * Пользователь. Список созданных вызовов
 *
 * https://uponblog.ru/api_list.php?page=78
 *
 * @param token
 * @returns IChallengeListResponse
 */
export function getCreatedByUserChallenges(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_challenge_create_list.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, response.data];
                    }
                    throw Error('Request Challenges Failed');
            }
        });
    });
}
/**
 * Пользователь. Вызовы, в которых участвует
 *
 * https://uponblog.ru/api_list.php?page=79
 *
 * @param token
 * @returns IChallengeListResponse
 */
export function getUserTakePartChallenges(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'user_challenge_take_part_list.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = _a.sent();
                    if (response.status === 200) {
                        return [2 /*return*/, response.data];
                    }
                    throw Error('Request Challenges Failed');
            }
        });
    });
}
/**
 * Шаг 1. Вызов. Результаты. Статусы
 *
 * https://uponblog.ru/api_list.php?page=82
 *
 * @param data
 * @returns IDualChallengeResults
 */
export function getPlayersResults(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(data === null || data === void 0 ? void 0 : data.token_challenge)) return [3 /*break*/, 2];
                    url = 'challenge_users_result_status.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Участник. Загружен ли результат
 *
 * https://uponblog.ru/api_list.php?page=81
 *
 * @param data
 * @returns IPlayerResultUpload
 */
export function isPlayerResultUpload(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_result_isset.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов каждому. Подать апелляцию
 *
 * https://uponblog.ru/api_list.php?page=87
 *
 * @param {IAppealPlayerResult} data
 * @returns
 */
export function appealPlayerResult(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_result_appeal.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Оценка челленджа
 *
 * https://uponblog.ru/api_list.php?page=85
 *
 * @param {IEstimateChallenge} data
 * @returns
 */
export function estimateChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_estimate.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов. Участник. Отправить на судейство.
 *
 * https://uponblog.ru/api_list.php?page=84
 *
 * @param {IChallangeResponse} data
 * @returns
 */
export function sendJudge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_send_judge.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Поиск
 *
 * Шаг 1. поиск - /api/search.php
 *
 * https://uponblog.ru/api_list.php?page=92
 * @param {ISearchProps} data - Параметры запроса
 * @returns ISearchListResponse
 */
export function searchChallenges(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data.search) return [3 /*break*/, 2];
                    url = 'search.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, {}];
            }
        });
    });
}
/**
 * Вызов. Возможность исключить пользователя (создателем)
 *
 * https://uponblog.ru/api_list.php?page=96
 *
 *
 * @param {IExcludeUserData} data
 * @returns Response
 */
export function excludeUser(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_users_exclude.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Продвижение вызова создателем
 *
 * https://uponblog.ru/api_list.php?page=95
 *
 * @param {IChallengeInfoProps} data
 * @returns
 */
export function promoteChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = 'challenge_promotion.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
            }
        });
    });
}
/**
 * Вызов каждому. Закрыть вызов.
 *
 * https://uponblog.ru/api_list.php?page=105
 *
 * @param data
 * @returns IChallengeInfoProps
 */
export function closeChallenge(data) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(data === null || data === void 0 ? void 0 : data.token_challenge)) return [3 /*break*/, 2];
                    url = 'challenge_close.php';
                    return [4 /*yield*/, client.customPost(url, data)];
                case 1:
                    response = (_a.sent()).data;
                    return [2 /*return*/, response];
                case 2: return [2 /*return*/, {}];
            }
        });
    });
}
