var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
if (process.env.__MODE__ === 'development') {
    // eslint-disable-next-line no-unused-vars
    var TOKENS_POOL = [
        "c9b4b4c1b619ff82a191658feb76fa7bd08a3454dde7bf9e72ff233c22537a28bf388e0d1d1c47a016e388ac848a967f6101dbbed8df9b9eb287eacf935b0fa5",
        "da0cd457b2d598134ee3d90e95b50f6dd51353b5b7cb6dd5d58606882921464a90a9639e711be9fd275f6740d95bb6151d13102859284bd1fc39cdb55f5cbab1",
        "115eae15d010d93cafd35106fb19752974c89acde5cfc1d237e340c8444b5d18ae6e323ec1d3c4a7ec7b1998f7b1468e3c6e0c1f116a3552b7adfacd69090c15",
        // Default
        "5075b946b65d8c2f7532fe0a050c1bc7615f1b27d88d5cceddf03ff3a9c8b119e5b42a15b4683343b7311fe1305d14fe5cd2581a1887a18278ed67f768202f0a",
        // ALEX
        "4482d8809a5e541e662f7f14f95bbae1f53de7c58f66e8bc77d4053025b8a253c3b65f9ea4ac49ee6faa21790d7a8ccbf38436b8f57db0574533e6756827a518"
    ];
}
var getClient = function () {
    // const BASE_URL = 'https://uponblog.ru/api/';
    var BASE_URL = process.env.__HOST__;
    // eslint-disable-next-line no-unused-vars
    // if (process.env.__MODE__ === 'development') {
    //     const DEV_TOKEN = '5075b946b65d8c2f7532fe0a050c1bc7615f1b27d88d5cceddf03ff3a9c8b119e5b42a15b4683343b7311fe1305d14fe5cd2581a1887a18278ed67f768202f0a'
    //     localStorage.setItem("X-Token", DEV_TOKEN);
    //     window.dispatchEvent(new Event('storage'));
    // }
    // @ts-ignore
    var client = axios.create({
        baseURL: BASE_URL,
        timeout: 120000,
        headers: {
            'Content-Type': 'text/plain',
        }
    });
    client.tokenField = "X-Token";
    client.tempTokenField = "X-TEMP-Token";
    client.langLSKey = "YourChallengeLanguage";
    client.customPost = function (url, data, config) { return __awaiter(void 0, void 0, void 0, function () {
        var token, lang, tokenData, response, error_1, authErrors;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    token = localStorage.getItem(client.tokenField);
                    lang = localStorage.getItem(client.langLSKey) || "ru";
                    tokenData = token ? { token: token } : {};
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.post(url, __assign(__assign(__assign({}, tokenData), data), { lang: lang }), config)];
                case 2:
                    response = _b.sent();
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _b.sent();
                    authErrors = [408, 401, 405];
                    if (authErrors.includes((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.status)) {
                        throw error_1;
                    }
                    else {
                        throw error_1;
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return client;
};
var client = getClient();
export default client;
